import { Variants, m } from "framer-motion"
import Image from "next/image"
import { memo, useMemo } from "react"

import Config from "@spatialsys/web/config"
import { spatialCdnImageLoader } from "@spatialsys/web/core/js/util/next-image-loader"

const FIRST_ROW_BRANDS = [
  {
    name: "Vogue",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/vogue.webp`,
  },
  {
    name: "Elle Magazine",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/elle-magazine.webp`,
  },
  {
    name: "Walmart",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/walmart.webp`,
  },
  {
    name: "BMW",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/bmw.webp`,
  },
  {
    name: "Jack Daniels",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/jack-daniels-honey.webp`,
  },
  {
    name: "C3",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/c3.webp`,
  },
  {
    name: "McDonalds",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/mcdonalds.webp`,
  },
]

const SECOND_ROW_BRANDS = [
  {
    name: "GameStop",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/game-stop.webp`,
  },
  {
    name: "Givenchy",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/givenchy.webp`,
  },
  {
    name: "Boss",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/boss.webp`,
  },
  {
    name: "Utah Jazz",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/utah-jazz.webp`,
  },
  {
    name: "Hublot",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/hublot.webp`,
  },
  {
    name: "Tommy Hilfiger",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/tommy-hilfiger.webp`,
  },
]

type BrandMarqueeProps = {
  isSmallScreen: boolean
}

export const BrandMarquee = memo(function BrandMarquee({ isSmallScreen }: BrandMarqueeProps) {
  // Right to left animation
  const marqueeAnimation = useMemo(() => {
    return {
      animate: {
        x: ["0%", "-100%"],
        transition: {
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: isSmallScreen ? 30 : 48,
            ease: "linear",
          },
        },
      },
    }
  }, [isSmallScreen])

  // Left to right animation
  const reverseMarqueeAnimation = {
    animate: {
      x: ["-100%", "0%"],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 30,
          ease: "linear",
        },
      },
    },
  }

  return (
    <>
      <BrandLogos
        animationVariants={marqueeAnimation}
        rowItems={isSmallScreen ? FIRST_ROW_BRANDS : [...FIRST_ROW_BRANDS, ...SECOND_ROW_BRANDS]}
      />
      {isSmallScreen && <BrandLogos animationVariants={reverseMarqueeAnimation} rowItems={SECOND_ROW_BRANDS} />}
    </>
  )
})

type BrandLogosProps = {
  animationVariants: Variants
  rowItems: {
    name: string
    image: string
  }[]
}

export const BrandLogos = memo(function BrandLogos({ animationVariants, rowItems }: BrandLogosProps) {
  return (
    <div className="relative -mt-2 flex h-[120px] w-[100vw] max-w-full items-center overflow-x-hidden">
      <div className="absolute inset-y-0 left-0 z-10 w-32 bg-gradient-to-r from-background via-background/80 via-30% to-transparent sm:w-48" />
      <div className="absolute flex w-max">
        {[...Array(3)].map((_, index) => (
          <m.div key={index} variants={animationVariants} animate="animate" aria-hidden={index > 0}>
            {rowItems.map(({ name, image }) => (
              <Image
                alt={name}
                key={`${name}-${index}`}
                src={image}
                priority
                width={80}
                height={80}
                className="brightness-80 mx-4 h-12 w-12 grayscale sm:mx-12 sm:h-20 sm:w-20"
                loader={spatialCdnImageLoader}
                unoptimized
              />
            ))}
          </m.div>
        ))}
      </div>
      <div className="absolute inset-y-0 right-0 z-10 w-32 bg-gradient-to-l from-background via-background/80 via-30% to-transparent sm:w-48" />
    </div>
  )
})
